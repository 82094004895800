import React, { ChangeEvent } from 'react';
import { Checkbox, FormControlLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { CasaviCustomField, CasaviFieldType } from '../../server/tickets/casavi.types';

export const CustomField = ({
	position,
	name,
	fieldType,
	required,
	description,
	dataJSON,
	onChange
}: Partial<CasaviCustomField> & { onChange: (e: ChangeEvent<HTMLInputElement>) => void }) => {
	const props = {
		name,
		label: description,
		required,
		onChange,
		fullWidth: true,
		variant: 'outlined' as const
	};

	return (
		<Stack>
			{!!name && (
				<Typography variant="h6" sx={{ my: 2 }}>
					{1 + position}. {name} {required && '*'}
				</Typography>
			)}
			{(() => {
				const { options, decimalPlaces, withTime = false } = dataJSON ?? {};

				switch (fieldType) {
					case CasaviFieldType.TEXTFIELD:
						return <TextField {...props} type="text" />;
					case CasaviFieldType.TEXTBOX:
						return <TextField {...props} multiline rows={5} sx={{ resize: 'none' }} />;
					case CasaviFieldType.NUMBER:
						return (
							<TextField
								{...props}
								type={decimalPlaces < 3 ? 'range' : 'number'}
								inputProps={{
									step: Number('0.' + '0'.repeat(decimalPlaces - 1) + '1'),
									max: Number('9'.repeat(decimalPlaces))
								}}
							/>
						);
					case CasaviFieldType.CHECKBOX:
						return <FormControlLabel {...props} control={<Checkbox name={name} value={null} />} />;
					case CasaviFieldType.DROPDOWN:
						return (
							<TextField {...props} select>
								{[...(options || [])].map((option, id) => (
									<MenuItem key={id + option} value={option}>
										{option}
									</MenuItem>
								))}
							</TextField>
						);
					case CasaviFieldType.DATE:
						return withTime ? (
							<TextField {...props} type="datetime-local" />
						) : (
							<TextField {...props} type="date" />
						);
					case CasaviFieldType.DOCUMENT:
						return (
							<TextField
								{...props}
								type="file"
								inputProps={{
									multiple: true,
									style: { height: '5rem' },
									maxSize: 20 * 1024 * 1024
								}}
							/>
						);
					case CasaviFieldType.SERVICE_PROVIDER_CONTRACT:
						return (
							<TextField
								{...props}
								type="file"
								inputProps={{
									accept: 'application/pdf',
									multiple: true,
									style: { height: '5rem' }
								}}
							/>
						);
					default:
						return null;
				}
			})()}
		</Stack>
	);
};
