import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ModifySearchOptions,
	initTicketList,
	modifySearch,
	setFilters,
	loadMoreTickets
} from '../actions';
import makeStyles from '@mui/styles/makeStyles';
import {
	Box,
	Card,
	Fab,
	Grid,
	TableCell,
	Theme,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { State } from 'app/core/reducers';
import { useTranslation } from 'react-i18next';
import TableRowOrCard, { TableCtx } from '../../../mui-custom/TableRowOrCard';
import { dateTime } from '../../../lib/dateHelper';
import { Image } from '../../../components/Image';
import { useMobile } from '../../../hooks/useBreakpoints';
import { useCheckViewport } from '../../../hooks/useCheckViewport';
import AddIcon from '@mui/icons-material/Add';
import { Filter } from '../../../mui-custom/Filter';
import {
	AnchorEnum,
	FilterTypeEnum,
	FiltersFromUrl
} from '../../../mui-custom/Filter/filters.types';
import { loadCustomers } from '../../customers/actions';
import { loadProperties } from '../../properties/actions';
import { UICustomer } from 'server/customers/browser.types';
import { UIProperty } from 'server/properties/browser.types';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { LoadingScreen } from '../../../components/LoadingScreen';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';

export const LIMIT = 20;

const useStyles = makeStyles(({ spacing }: Theme) => ({
	wrapper: {
		width: '100%',
		height: '100%'
	},
	imageMobile: {
		width: '100%'
	},
	outer: {
		overflowY: 'hidden',
		width: '100%',
		display: 'flex',
		flexDirection: 'column'
	},
	gridContainer: {
		'@media print': {
			display: 'block'
		}
	},
	gridContainerWithStaticHeader: {
		height: `calc(100% - 2 * ${spacing(5)})`
	}
}));

export interface FiltersState {
	readonly customer: UICustomer | { id: string };
	readonly property: UIProperty | { id: string };
}

export const ListPage = (props) => {
	const history = useHistory();
	const { t } = useTranslation();
	const isMobile = useMobile();
	const classes = useStyles();
	const createTicket = () => alert('Nö mit Ö');
	const theme = useTheme();
	const dispatch = useDispatch();

	const { ticketList } = useSelector((state: State) => state.tickets);
	const { list: customerList } = useSelector((state: State) => state.customers);
	const { list: propertyList } = useSelector((state: State) => state.properties);

	const [localFilters, setLocalFilters] = useState<Partial<FiltersFromUrl> | false>(false);
	const [offset, setOffset] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const [existMoreTickets, setExistMoreTickets] = useState(true);

	useEffect(() => {
		dispatch(loadCustomers());
		dispatch(loadProperties());
		const _search: ModifySearchOptions = {
			mode: 'merge',
			data: { action: undefined }
		};
		const filtersFromUrl = modifySearch(history, _search);
		setLocalFilters(filtersFromUrl);
		dispatch(initTicketList({ limit: LIMIT, offset, filters: localFilters as object })).then(() =>
			setIsLoading(false)
		);
	}, []);

	const openTicket = (ticketId: number) => {
		history.push({
			pathname: `/app/tickets/${ticketId}`
		});
	};

	const handleFilterChange = (newValue) => {
		setOffset(0);
		setExistMoreTickets(true);
		newValue = {
			searchTerm: newValue?.searchTerm,
			customerIds: newValue?.customer?.join(',') || undefined,
			propertyIds: newValue?.property?.join(',') || undefined
		};
		setLocalFilters(newValue);
		dispatch(setFilters(newValue));
	};

	/* BELOW FOR INFINITY SCROLL */
	const loadMore = () => {
		setExistMoreTickets(true);
		dispatch(
			loadMoreTickets({ limit: LIMIT, offset: offset + LIMIT, filters: localFilters as object })
		).then(() => {
			setIsLoading(false);
			setTimeout(() => {
				if (ticketList?.length < offset + LIMIT) {
					setExistMoreTickets(false);
				}
				setOffset(offset + LIMIT);
			}, 100);
		});
	};

	const lastElementRef = useRef<HTMLDivElement>(null);
	const isViewingLastElement = useCheckViewport(lastElementRef);
	useEffect(() => {
		if (isLoading) return;
		setIsLoading(true);
		setTimeout(() => {
			loadMore();
		}, 100);
	}, [isViewingLastElement]);
	/* INFINITY SCROLL END */

	const hasBigWidth = useMediaQuery(theme.breakpoints.up('md'));
	return (
		<div className={classes.wrapper}>
			<ActionBar>
				<Typography variant="h6">{t('general:sidebar_tickets')}</Typography>
			</ActionBar>
			<Grid
				container
				className={clsx([
					classes.gridContainer,
					{
						[classes.gridContainerWithStaticHeader]: hasBigWidth
					}
				])}
				sx={{ overflowY: 'auto' }}
			>
				<Grid item xs={12}>
					<Card className={classes.outer} sx={{ m: isMobile ? 0 : 2 }}>
						{localFilters && (
							<Filter
								isChangingFilter={false}
								side={AnchorEnum.RIGHT}
								onChange={handleFilterChange}
								drawerHeadline={t('tickets:filter_switch_label')}
								textSearch={{
									filterKey: 'searchTerm',
									placeholder: t('orders:filter_search_order_title'),
									defaultValue: localFilters?.searchTerm
								}}
								filters={[
									{
										filterType: FilterTypeEnum.CHIP,
										filterKey: 'customer',
										placeholder: t('tickets:label_customer'),
										items: customerList?.data
											?.filter((c) => c.casaviTenantId)
											?.map((customer) => ({
												label: customer.name,
												value: customer.id
											})),
										defaultValue: localFilters?.customerIds?.split(',') || []
									},
									{
										filterType: FilterTypeEnum.CHIP,
										filterKey: 'property',
										placeholder: t('tickets:label_property'),
										items: propertyList?.data?.map((property) => ({
											label: property.name,
											value: property.id
										})),
										defaultValue: localFilters?.propertyIds?.split(',') || []
									}
								]}
							/>
						)}
						<TableCtx
							headers={[
								t('tickets:label_attachment'),
								t('tickets:label_createdAt'),
								t('tickets:label_title'),
								t('tickets:label_property_manager'),
								t('tickets:label_property')
							]}
						>
							{ticketList?.map((ticket) => (
								<TableRowOrCard
									sx={{
										cursor: 'pointer',
										'&:hover': { backgroundColor: theme?.palette?.secondaryShades4p }
									}}
									onClick={() => openTicket(ticket.id)}
									key={ticket.id}
								>
									<TableCell>
										<div>
											<Image
												className={isMobile ? classes.imageMobile : ''}
												src={
													ticket?.Attachments[0]?.temporaryThumbUrl ||
													'/static/images/CardMedia.png'
												}
											/>
										</div>
									</TableCell>
									<TableCell>
										<Typography variant="body2">
											{dateTime(ticket.createdAt).toFormat('dd.MM.yyyy HH:mm')}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="h6">{ticket.title}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1">{ticket?.Tenant?.name}</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="body1">{ticket?.Community?.name}</Typography>
									</TableCell>
								</TableRowOrCard>
							))}
						</TableCtx>
						{ticketList?.length && existMoreTickets && !isLoading && (
							<div ref={lastElementRef}></div>
						)}
						{isLoading && <LoadingScreen />}
						<Box
							sx={{
								'& > :not(style)': {
									m: 1,
									borderRadius: 4,
									position: 'absolute',
									right: theme.spacing(2),
									bottom: theme.spacing(2)
								}
							}}
						>
							<Fab variant="extended" color="primary" onClick={() => createTicket()}>
								<AddIcon />
								{t('tickets:create_new_ticket')}
							</Fab>
						</Box>
					</Card>
				</Grid>
			</Grid>
		</div>
	);
};
