import React, { FormEvent, ChangeEvent, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountSpecificFeature, useAccountSpecificFeature } from '../../acl/features';
import { State } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loadTicketCustomFields } from '../actions';
import ActionBar from '../../../mui-custom/ActionBar/ActionBar';
import { CustomField } from '../../../components/CustomField';
import { useMobile } from '../../../hooks/useBreakpoints';
import { api } from '../../../lib/api';

export const AddCustomFieldsPage = ({ match }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const isMobile = useMobile();

	const { ticketCustomFields } = useSelector((state: State) => state.tickets);
	const { search } = useLocation();
	useEffect(() => {
		dispatch(loadTicketCustomFields({ casaviTenantId, ticketTypeId }));
	}, [search]);

	const [values, setValues] = useState<{ [x: string]: unknown }>({});

	const hasTicketsCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	if (!hasTicketsCreation) return null;

	const queryParams = new URLSearchParams(search);
	const ids: number[] = JSON.parse(queryParams.get('ids'));
	const [propertyManagerId, propertyId, ticketTypeId, casaviTenantId] = ids ?? [];
	if (!propertyManagerId || !propertyId || !ticketTypeId || !casaviTenantId) return null;

	const setField =
		(position: number) =>
		({ target }: ChangeEvent<HTMLInputElement>) => {
			const typesToValues = {
				file: target.files,
				checkbox: target.checked
			};
			setValues((prev) => ({
				...prev,
				[position]: typesToValues[target.type] ?? target.value ?? null
			}));
		};

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const positionToAttributes = {};
		ticketCustomFields.forEach(({ id, position, name, description }) => {
			positionToAttributes[position] = { id, name, description };
		});

		const formData = new FormData();
		Object.entries(values).forEach(([_position, _value]) => {
			if (_value instanceof FileList) {
				Array.from(_value).forEach((file) => formData.append(_position, file));
			} else {
				formData.append(_position, _value as string | Blob);
			}
		});

		formData.append('positionToAttributes', JSON.stringify(positionToAttributes));

		try {
			const response = await api.post('/api/v1/tickets/create-casavi-ticket', formData);
			if (!response.ok) throw new Error('Failed to submit form');

			console.log('Form submitted successfully');
		} catch (error) {
			console.error('Error submitting form:', error);
		}
	};

	return (
		<Stack sx={{ mx: 1, pb: 1 }}>
			<Typography variant="h6">{t('2. custom fields part: <-- translated text here')}</Typography>
			<br />
			<form onSubmit={handleSubmit}>
				{[...ticketCustomFields].map((props) => (
					<CustomField key={props.position} {...props} onChange={setField(props.position)} />
				))}
				<br />
				<ActionBar
					primary={{
						children: t('general:submit'),
						type: 'submit'
					}}
					alternate={{
						children: t('general:cta_go_back'),
						href: '/app/tickets/add', // Add modal ask for confirming if want to leave/cancel/delete the temp data/ticket creation
						disabled: !isMobile
					}}
				/>
			</form>
		</Stack>
	);
};
