import React, { useEffect, useRef } from 'react';
import { State } from 'app/core/reducers';
import Paper from '@mui/material/Paper';
import { dateTime } from '../../../lib/dateHelper';
import { TableCell, Typography, useTheme } from '@mui/material';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { LoadingScreen } from '../../../components/LoadingScreen';
import { useDispatch, useSelector } from 'react-redux';
import TableRowOrCard, { TableCtx } from '../../../mui-custom/TableRowOrCard';
import { fetchMoreOrders } from '../actions';
import { UIOrder } from '../../../../server/orders/browser.types';
import { getAddress } from '../../../lib/address';
import { mapStatusToColor } from '../../../lib/types';
import { useCheckViewport } from '../../../hooks/useCheckViewport';

export const getOrderTitle = (order: UIOrder) =>
	!order.title || order.title === 'Auftragstitel' ? order.type : order.title;

export const OrderList = ({ filteredList }: { filteredList: UIOrder[] }) => {
	const { list, isLoading } = useSelector((state: State) => state.orders);
	const theme = useTheme();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const history = useHistory();
	const mapStatusToLabel = {
		ALL: t('orders:status_ALL').toUpperCase(),
		OPEN: t('orders:status_OPEN').toUpperCase(),
		IN_PROGRESS: t('orders:status_IN_PROGRESS').toUpperCase(),
		FINISHED: t('orders:status_FINISHED').toUpperCase(),
		DECLINED: t('orders:status_DECLINED').toUpperCase()
	};
	const handleCellClick = (orderId: number) => {
		history.push({
			pathname: `/app/orders/${orderId}`,
			search
		});
	};

	const lastElementRef = useRef<HTMLDivElement>(null);
	const isViewingLastElement = useCheckViewport(lastElementRef);

	useEffect(() => {
		if (list?.data?.length && list.metaData?.hasMore) dispatch(fetchMoreOrders());
	}, [isViewingLastElement]);

	if (!list?.data?.length) {
		return (
			<Paper variant="outlined" sx={{ m: 1, py: 3, px: 1, width: 'auto' }}>
				<Typography>{t('orders:list_none_here')}</Typography>
			</Paper>
		);
	}

	return (
		<TableCtx
			headers={[
				t('orders:label_createdAt'),
				t('orders:label_title'),
				t('orders:label_due_date'),
				t('orders:label_status'),
				t('orders:label_identifier'),
				t('orders:label_internal_id'),
				t('orders:label_address'),
				t('orders:label_creator'),
				t('orders:label_assignee')
			]}
		>
			{filteredList.map((order) => (
				<TableRowOrCard
					sx={{
						cursor: 'pointer',
						'&:hover': { backgroundColor: theme?.palette?.secondaryShades4p }
					}}
					onClick={() => handleCellClick(order.id)}
					key={order.id}
				>
					<TableCell>
						<Typography variant="body2">
							{dateTime(order.createdAt).toFormat('dd.MM.yyyy HH:mm')}
						</Typography>
					</TableCell>
					<TableCell>{getOrderTitle(order)}</TableCell>
					<TableCell>
						{order.dueDate ? dateTime(order.dueDate).toFormat('dd.MM.yyyy') : ''}
					</TableCell>
					<TableCell>
						<Chip
							color={mapStatusToColor[order.status]}
							size="small"
							sx={{ borderRadius: '4px', minWidth: theme.spacing(11) }}
							label={mapStatusToLabel[order.status] ?? order.status}
						>
							{order.status}
						</Chip>
					</TableCell>
					<TableCell>{order.taskId}</TableCell>
					<TableCell>{order.internalId}</TableCell>
					<TableCell>{getAddress(order?.address)}</TableCell>
					<TableCell>{order?.customer?.name}</TableCell>
					<TableCell>{order?.assignee?.lastName}</TableCell>
				</TableRowOrCard>
			))}

			{list?.metaData?.hasMore && <div ref={lastElementRef}>{isLoading && <LoadingScreen />}</div>}
		</TableCtx>
	);
};
