import { FiltersFromUrl } from 'app/mui-custom/Filter/filters.types';
import { History } from 'history';
import { pickBy } from 'lodash';
import qs from 'qs';
import { createConstants } from '../../../app/lib/createConstants';
import { api } from '../../lib/api';
import { createApiAction } from '../../redux/createApiAction';
import { history } from '../history';
import { State } from '../reducers';
import { FiltersState, LIMIT } from './list/ListPage';

export const constants = createConstants(['SET_FILTERS']);

export const loadProperties = createApiAction(
	'tickets/load_property',
	({ accountId, customerId }: { accountId: number; customerId: number }) => {
		return api.get(`/api/v1/properties/account/${accountId}/customer/${customerId}`);
	}
);

export const loadTicketTypes = createApiAction(
	'tickets/load_ticket_type',
	({ casaviTenantId, casaviContactId }: { casaviTenantId: number; casaviContactId: number }) => {
		return api.get(
			`/api/v1/tickets/tenant/${casaviTenantId}/contact/${casaviContactId}/ticket-types`
		);
	}
);

export const loadTicketCustomFields = createApiAction(
	'tickets/load_custom_fields',
	({ casaviTenantId, ticketTypeId }: { casaviTenantId: number; ticketTypeId: number }) => {
		return api.get(
			`/api/v1/tickets/tenant/${casaviTenantId}/ticket-type/${ticketTypeId}/custom-fields`
		);
	}
);

export const initTicketList = createApiAction(
	'tickets/load_tickets_list',
	({ limit, offset, filters }: { limit: number; offset: number; filters?: object }) => {
		return api.get(`/api/v1/tickets?limit=${limit}&offset=${offset}`, { params: filters });
	}
);

export const loadMoreTickets = createApiAction(
	'tickets/load_more_tickets_list',
	({ limit, offset, filters }: { limit: number; offset: number; filters?: object }) => {
		return api.get(`/api/v1/tickets?limit=${limit}&offset=${offset}`, { params: filters });
	}
);

export const loadTicketDetails = createApiAction('tickets/load_ticket_detail', (id: number) => {
	return api.get(`/api/v1/tickets/${id}`);
});

type SearchModes = 'merge' | 'replace';

export interface ModifySearchOptions {
	mode: SearchModes;
	data: Partial<FiltersFromUrl>;
}

export function modifySearch(
	_history: History,
	options: ModifySearchOptions
): Partial<FiltersFromUrl> {
	const currentSearch = qs.parse(_history.location.search, {
		ignoreQueryPrefix: true
	});
	if (currentSearch.status && typeof currentSearch.status === 'string') {
		currentSearch.status = currentSearch.status.split(',');
	}
	if (options.mode === 'replace') {
		return pickBy(options.data);
	} else {
		return pickBy({ ...currentSearch, ...options.data });
	}
}

type HistoryModes = 'replace' | 'push';
interface ModifyHistoryOptions {
	search: ModifySearchOptions;
	pathname?: string;
	mode?: HistoryModes;
}

function modifyHistory(_history: History, options: ModifyHistoryOptions): void {
	const search = modifySearch(_history, options.search) as any;
	if (search.status && Array.isArray(search.status)) {
		search.status = search.status.join(',');
	}
	switch (options.mode) {
		case 'push':
			return history.push({
				pathname: options.pathname,
				search: qs.stringify(search)
			});
		default:
			return history.replace({
				pathname: options.pathname,
				search: qs.stringify(search)
			});
	}
}

interface SetFiltersInURLOptions {
	navigateTo?: string;
}

export function setFiltersInURL(
	filters: Partial<FiltersState>,
	options?: SetFiltersInURLOptions
): boolean {
	const pickedFilters = pickBy(filters, (val, key) => {
		return key !== 'collapsed' && key !== 'fullText' && key !== 'dialogOpen';
	});
	modifyHistory(history, {
		pathname: options?.navigateTo,
		search: { mode: 'merge', data: pickedFilters },
		mode: 'replace'
	});
	if (Object.keys(pickedFilters).filter((val) => val !== 'status').length) {
		return true;
	}

	return false;
}

export const setFilters = (filters: Partial<FiltersState>) => (dispatch, getState: () => State) => {
	dispatch({
		type: constants.SET_FILTERS,
		payload: filters
	});
	setFiltersInURL(filters);
	dispatch(initTicketList({ limit: LIMIT, offset: 0, filters }));
};
