import { useMediaQuery, useTheme, Grid, Typography, Card, Chip, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import AddIcon from '@mui/icons-material/Add';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import {
	setFiltersV2,
	startListeningForOrders,
	stopListeningForOrders,
	getStatusForOrder,
	openAddDialog,
	modifySearch,
	ModifySearchOptions
} from './actions';
import { OrderList } from './list/List';
import { FiltersState } from './reducers';
import { OrderDetailView } from './single/DetailView';
import { OrderAddPage } from './AddDialog';
import { useHistory, useLocation } from 'react-router';
import {
	AccountSpecificFeature,
	Feature,
	useAccountSpecificFeature,
	useFeature
} from '../acl/features';
import clsx from 'clsx';

import MuiAlert from '@mui/material/Alert';
import MuiAlertTitle from '@mui/material/AlertTitle';

import { loadCustomers } from '../customers/actions';
import { searchEmployees } from './helper';
import { Filter } from '../../mui-custom/Filter';
import { AnchorEnum, FilterTypeEnum, FiltersFromUrl } from '../../mui-custom/Filter/filters.types';
import { State } from '../reducers';
import ActionBar from '../../mui-custom/ActionBar/ActionBar';
import { useMobile } from '../../hooks/useBreakpoints';
import { ArrowBack } from '@mui/icons-material';
import { PrimaryButton, SecondaryButton } from '../../mui-custom/Button';
import { useCheckCredits } from './single/InitialActions';
import { useAccountDeactivatedCheck } from '../../hooks/useAccountDeactivatedCheck';
import { DateTime } from 'luxon';
import { ChargebeeSubscriptionStatus } from '../../../server/chargebee/chargebee.enums';
import { getActiveSubscription } from '../admin/account/actions';
import { api } from '../../lib/api';
import { UIOrderStatusFilters } from '@api/v1';

export const statuses: UIOrderStatusFilters[] = [
	'OPEN',
	'IN_PROGRESS',
	'FINISHED',
	'DECLINED',
	'ALL'
];

const useStyles = makeStyles(({ spacing }: Theme) => ({
	wrapper: {
		width: '100%',
		height: '100%'
	},
	outer: {
		overflowY: 'hidden',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	gridContainer: {
		height: '100%',
		'@media print': {
			display: 'block'
		}
	},
	gridContainerWithStaticHeader: {
		height: `calc(100% - 2 * ${spacing(5)})`
	},
	fab: {
		position: 'fixed',
		right: spacing(2),
		bottom: spacing(2),
		marginTop: spacing(1),
		marginLeft: spacing(2),
		borderRadius: spacing(2),
		'@media print': {
			display: 'none'
		}
	},
	hideOnPrint: {
		'@media print': {
			display: 'none'
		}
	}
}));

enum NAV {
	LIST_PAGE = 'orders:list_page_title',
	ADDING_PAGE = 'orders:add_page_title',
	DETAIL_PAGE = 'orders:label_details'
}

/* this should be moved to components and have storybook examples */
interface PageNavProps<T> {
	onAction?: () => void;
	setActivePage: React.Dispatch<React.SetStateAction<T>>;
	activePage: T;
	[x: string]: unknown;
}
const PageNav = ({
	onAction,
	setActivePage,
	activePage = NAV.LIST_PAGE,
	...rest
}: PageNavProps<NAV>) => {
	const { t } = useTranslation();
	const isMobile = useMobile();
	const isNavigated = activePage !== NAV.LIST_PAGE;
	const { account } = useSelector((state: State) => state.admin.account);
	const { activeSubscription } = useSelector((state: State) => state.admin.account);
	const { isOutOfCredits } = useCheckCredits();
	const hasPricingModelV2 = useAccountSpecificFeature(AccountSpecificFeature.PricingModelV2);
	const { accountDeactivated } = useAccountDeactivatedCheck();

	return (
		<ActionBar
			alternate={
				isNavigated
					? {
							onClick: () => setActivePage(NAV.LIST_PAGE),
							children: <ArrowBack />
					  }
					: null
			}
			primary={
				isMobile || isNavigated || !onAction
					? null
					: { children: t(NAV.ADDING_PAGE), onClick: onAction }
			}
			sx={{ borderRadius: 0, borderBottomWidth: 1 }}
			{...rest}
		>
			<Typography variant="h6">
				{t(activePage)}
				{account && hasPricingModelV2 && activeSubscription && !accountDeactivated && (
					<>
						{' '}
						{activeSubscription.status === ChargebeeSubscriptionStatus.IN_TRIAL ? (
							<Chip color="info" label={t('admin:account_pricing_order_credits_trial')} />
						) : (
							!isOutOfCredits && (
								<Chip
									color="info"
									label={t('admin:account_pricing_order_credits_available', {
										usedCredits: account.usedOrderCredits,
										totalCredits: account.orderCreditsAddon + account.orderCreditsPlan,
										month: DateTime.local({ locale: 'de' }).toFormat('MMMM')
									})}
								/>
							)
						)}
					</>
				)}
			</Typography>
		</ActionBar>
	);
};

export const OrderApp = (props) => {
	const { pathname, search } = useLocation();
	const canCreateOrder = useFeature(Feature.CreateOrder);
	const hasTicketCreation = useAccountSpecificFeature(AccountSpecificFeature.TicketCreation);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const theme = useTheme();
	const hasBigWidth = useMediaQuery(theme.breakpoints.up('md'));
	const classes = useStyles(props);
	const hideDetails = props.match.isExact;
	const history = useHistory();

	const { isOutOfCredits } = useCheckCredits();

	const { accountDeactivated } = useAccountDeactivatedCheck();
	const [casaviOrders, setCasaviOrders] = useState(0);
	function getOrders() {
		api
			.get('/api/v1/casavi/orders')
			.then((data) => {
				setCasaviOrders(data);
			})
			.catch((error) => {
				console.error('Error fetching orders: ', error);
			});
	}
	useEffect(() => {
		getOrders();
	}, [accountDeactivated]);

	const { activeSubscription } = useSelector((state: State) => state.admin.account);

	const { list } = useSelector((state: State) => state.orders);

	const { customers } = useSelector((state: State) => state);
	const [assigneeList, setAssigneeList] = useState([]);
	const [filtersFromUrl, setFiltersFromUrl] = useState<Partial<FiltersFromUrl> | false>(false);
	const isMobile = useMobile();
	const [page, setPage] = useState<NAV>();

	useEffect(() => {
		setPage(hideDetails ? NAV.LIST_PAGE : NAV.DETAIL_PAGE);
	}, [hideDetails]);

	const navToListPage = () => {
		setPage(NAV.LIST_PAGE);
		history.goBack();
	};

	const navToAddPage = () => {
		dispatch(openAddDialog());
		setPage(NAV.ADDING_PAGE);
	};

	const getEmployees = async () => {
		const employees = searchEmployees();
		setAssigneeList(await employees);
	};
	useEffect(() => {
		let _filters = getFiltersFromSearch(search);
		async function _updateFilters() {
			const orderId = pathname.split('/').pop();
			if (Number(orderId)) {
				_filters = await getStatusForOrder(orderId);
			} else {
				_filters = { status: 'ALL' };
			}
		}
		if (!_filters.status) _updateFilters();
		const _search: ModifySearchOptions = {
			mode: 'merge',
			data: { action: undefined }
		};

		const filtersFromUrl = modifySearch(history, _search);
		filtersFromUrl.status = filtersFromUrl?.status || ['ALL'];
		setFiltersFromUrl(filtersFromUrl);

		dispatch(setFiltersV2(_filters));
		dispatch(startListeningForOrders());

		getEmployees();
		dispatch(loadCustomers());
		dispatch(getActiveSubscription());

		return stopListeningForOrders;
	}, []);

	function handleFilterChange(newValue) {
		newValue = {
			...newValue,
			status: newValue?.status || [],
			assignee: newValue?.assignee ? { id: newValue?.assignee } : undefined,
			customer: newValue?.property_manager ? { id: newValue?.property_manager } : undefined,
			searchString: newValue?.title
		};
		delete newValue.title;
		dispatch(setFiltersV2(newValue));
	}

	return (
		<div className={classes.wrapper}>
			{isOutOfCredits &&
				!accountDeactivated &&
				activeSubscription?.status !== ChargebeeSubscriptionStatus.IN_TRIAL && (
					<MuiAlert
						sx={{ padding: `${theme.spacing(0.5)} ${theme.spacing(1)}` }}
						severity="warning"
						icon={false}
					>
						{t('admin:account_pricing_order_credits_exceeded')}
					</MuiAlert>
				)}
			<PageNav
				setActivePage={navToListPage}
				activePage={page}
				onAction={canCreateOrder && navToAddPage}
				className={classes.hideOnPrint}
			/>
			<Grid
				container
				className={clsx([
					classes.gridContainer,
					{
						[classes.gridContainerWithStaticHeader]: hasBigWidth
					}
				])}
				sx={{ overflowY: 'auto' }}
			>
				<Grid item xs={12}>
					{accountDeactivated && (
						<MuiAlert
							severity="info"
							icon={false}
							action={
								<Link to="/app/settings/subscription">
									<SecondaryButton>
										{t('orders:account_deactivated_order_alert_plan_select')}
									</SecondaryButton>
								</Link>
							}
						>
							<MuiAlertTitle>
								{casaviOrders}
								{t('orders:account_deactivated_order_alert_title')}
							</MuiAlertTitle>
							<Typography variant="body1">{t('admin:account_deactivated_order_alert')}</Typography>
						</MuiAlert>
					)}
					{NAV.LIST_PAGE === page && (
						<Card className={classes.outer} sx={{ m: isMobile ? 0 : 2 }}>
							<Filter
								side={AnchorEnum.RIGHT}
								onChange={handleFilterChange}
								drawerHeadline={t('orders:filter_switch_label')}
								textSearch={{
									filterKey: 'title',
									placeholder: t('orders:filter_search_order_title'),
									defaultValue: filtersFromUrl?.title
								}}
								filters={[
									{
										filterType: FilterTypeEnum.CHIP,
										filterKey: 'status',
										placeholder: t('orders:label_display'),
										items: [
											{ label: t('orders:status_OPEN'), value: 'OPEN' },
											{ label: t('orders:status_DECLINED'), value: 'DECLINED' },
											{ label: t('orders:status_IN_PROGRESS'), value: 'IN_PROGRESS' },
											{ label: t('orders:status_FINISHED'), value: 'FINISHED' }
										],
										defaultValue: filtersFromUrl?.status?.filter((s) => s !== 'ALL') || []
									},
									{
										filterType: FilterTypeEnum.CHIP,
										filterKey: 'assignee',
										placeholder: t('orders:label_assignee'),
										items: assigneeList?.map((employee) => ({
											label: `${employee.firstName} ${employee.lastName}`,
											value: String(employee.id)
										})),
										defaultValue: filtersFromUrl?.assignee?.id || []
									},
									{
										filterType: FilterTypeEnum.CHIP,
										filterKey: 'property_manager',
										placeholder: t('orders:label_property_management'),
										items: customers?.list?.data?.map((customer) => ({
											label: customer.name,
											value: String(customer.id)
										})),
										defaultValue: filtersFromUrl?.customer?.id || []
									}
								]}
							/>
							<OrderList filteredList={list?.data} />
							{canCreateOrder && isMobile && (
								<PrimaryButton className={classes.fab} onClick={navToAddPage} id="cta_new_order">
									<AddIcon />
									{t('orders:cta_new_order')}
								</PrimaryButton>
							)}
							{hasTicketCreation && (
								<PrimaryButton
									className={classes.fab}
									onClick={() => history.push('/app/tickets/add')}
									id="cta_new_tickets"
								>
									<AddIcon />
									{t('tickets:creation_cta')}
								</PrimaryButton>
							)}
						</Card>
					)}
					{NAV.DETAIL_PAGE === page && (
						<Route path="/app/orders/:orderId" component={OrderDetailView} />
					)}
				</Grid>
				{NAV.ADDING_PAGE === page && <OrderAddPage backToListPage={navToListPage} />}
			</Grid>
		</div>
	);
};

function getFiltersFromSearch(search: string): Partial<FiltersState> {
	const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });
	parsedSearch.status = getStatusFromSearch(parsedSearch.status);
	return parsedSearch;
}

function getStatusFromSearch(status) {
	let statusFilter: string[];
	const filters = status ? status.split(',') : ['ALL'];
	if (filters.length > 0) {
		statusFilter = filters.filter((filter) => statuses.includes(filter));
	} else {
		statusFilter = ['ALL'];
	}
	return statusFilter.join(',');
}
