import { combineReducers } from 'redux';
import { createReducer } from '../../redux/createReducer';
import {
	loadProperties,
	loadTicketDetails,
	loadTicketTypes,
	initTicketList,
	loadMoreTickets,
	loadTicketCustomFields
} from './actions';
import { Property } from '../../../server/db/models/Property';
import { CasaviCustomField } from '@api/v1';
import { CasaviTicket } from './casavi.types';

export interface UITicketTypes {
	id: number;
	name: string;
}

export interface UITicket extends Partial<CasaviTicket> {}

export interface TicketsState {
	properties: Property[];
	ticketTypes: UITicketTypes[];
	ticketCustomFields: CasaviCustomField[];
	ticketList: UITicket[];
	ticketDetails: UITicket & { isLoading?: boolean };
	[x: string]: unknown /* reserved for custom fields and temp saved form data */;
}

export const ticketsReducers = combineReducers<TicketsState>({
	properties: createReducer<Property[]>([]).handleAction(
		loadProperties.success,
		(_state, action) => action.payload
	),
	ticketTypes: createReducer<UITicketTypes[]>([]).handleAction(
		loadTicketTypes.success,
		(_state, action) => action.payload
	),
	ticketCustomFields: createReducer<CasaviCustomField[]>([]).handleAction(
		loadTicketCustomFields.success,
		(_state, action) => action.payload
	),
	ticketList: createReducer<UITicket[]>([])
		.handleAction(initTicketList.success, (_state, action) => action.payload)
		.handleAction(loadMoreTickets.success, (state, action) => [...state, ...action.payload]),
	ticketDetails: createReducer<UITicket & { isLoading?: boolean }>({})
		.handleAction(loadTicketDetails.success, (_state, action) => action.payload)
		.handleAction(loadTicketDetails.request, (state, action) => ({
			...state,
			isLoading: true
		}))
});
